import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Switch,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import {
  Room as RoomIcon,
  AccountCircle as AccountCircleIcon,
  EventNote as EventNoteIcon,
  NotificationsNone as NotificationsNoneIcon,
} from '@material-ui/icons'
import jwt_decode from 'jwt-decode'
import { NavLink } from 'react-router-dom'
import { useGetOne, usePutMutation } from 'src/hooks/useQueries'
import type {
  extendedToken,
  ExtendedUserExtranet,
} from 'src/types/extendedTypes'

// eslint-disable-next-line @getify/proper-arrows/where
const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      padding: `${theme.spacing(2)} 0`,
      fontWeight: 'bold',
      justifyContent: 'space-between',
      borderBottom: `10px solid ${theme.palette.primary.light}`,
      '& .MuiListItemIcon-root': {
        minWidth: 0,
        padding: theme.spacing(0, 2),
        color: theme.palette.primary.main,
      },
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    item: {
      width: 'auto',
      fontWeight: 'bold',
    },
    Link: {
      textDecoration: 'none',
    },
  }
})

export function Header() {
  const classes = useStyles()
  const token = localStorage.getItem('jwt') ?? ''
  const decodedToken = jwt_decode<extendedToken>(token)

  const { data: me, refetch } = useGetOne<ExtendedUserExtranet>(
    'UserExtranet',
    {
      id: decodedToken.id,
    },
  )

  const { mutate } = usePutMutation(
    `UserExtranet/${decodedToken.id}`,
    {
      accept_alert_anomaly: !me?.data.accept_alert_anomaly,
    },
    // eslint-disable-next-line @getify/proper-arrows/where
    { onSuccess: () => refetch() },
  )

  function logOut() {
    localStorage.removeItem('jwt')
    window.location.href = '/login'
  }

  function onSubmit() {
    mutate()
  }
  if (!me) return <p>Chargement en cours...</p>
  return (
    <List className={classes.root}>
      <div style={{ display: 'flex' }}>
        <ListItem className={classes.item}>
          <ListItemIcon>
            <RoomIcon />
          </ListItemIcon>
          <NavLink to="/Adresses" className={classes.Link}>
            Vos adresses
          </NavLink>
        </ListItem>
        <ListItem className={classes.item}>
          <ListItemIcon>
            <EventNoteIcon />
          </ListItemIcon>
          <NavLink to="/Planning" className={classes.Link}>
            Planning
          </NavLink>
        </ListItem>
      </div>

      <ListItem className={classes.item}>
        <ListItemText primary="Alertes / anomalies" />
        <ListItemIcon>
          <NotificationsNoneIcon />
        </ListItemIcon>
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={() => onSubmit()}
            checked={me?.data.accept_alert_anomaly}
            color="primary"
          />
        </ListItemSecondaryAction>
      </ListItem>

      <div style={{ display: 'flex' }}>
        <ListItem className={classes.item} button>
          <ListItemText primary="Se déconnecter" onClick={() => logOut()} />
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
        </ListItem>
      </div>
    </List>
  )
}
