import {
  Container,
  Typography,
  Divider,
  CssBaseline,
  TextField,
  Button,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import jwtDecode from 'jwt-decode'
import { useHttpClient } from 'src/adapters/nxHttpClient'

// eslint-disable-next-line @getify/proper-arrows/where
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      height: '80vh',
      width: '50%',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: theme.spacing(4),
    },
    divider: {
      width: '80px',
      height: '3px',
      backgroundColor: theme.palette.primary.main,
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '50%',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  }
})

type RecoveryProps = {
  title: string
}

export function Recovery(props: RecoveryProps) {
  const { title } = props
  const classes = useStyles()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const decodedToken = jwtDecode<any>(token ?? '')

  const httpClient = useHttpClient()

  function handleSubmit(e: any) {
    e.preventDefault()
    httpClient
      .patch('/UserExtranet/newPassword', {
        id: decodedToken.id,
        password: e.target.password.value,
      })
      .then(() => {
        localStorage.removeItem('jwt')
        window.location.href = '/login'
      })
      .catch((error) => console.log(error))
  }

  return (
    <Container component="main" className={classes.container}>
      <Typography component="h1" variant="h4">
        {title} de <span style={{ color: 'green' }}>votre mot de passe</span>
      </Typography>
      <Divider classes={{ root: classes.divider }} />

      <CssBaseline />
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" style={{ fontWeight: 'bold' }}>
          Nouveau mot de passe
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Connexion
          </Button>
        </form>
      </div>
    </Container>
  )
}
