import { withStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'

// eslint-disable-next-line @getify/proper-arrows/where
export const CustomTooltip = withStyles((theme) => {
  return {
    tooltip: {
      backgroundColor: theme.palette.secondary.main,
      color: 'black',
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 'bold',
      padding: '4px',
    },
  }
})(Tooltip)
