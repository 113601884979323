export function listMonths(fromMonth: number) {
  return Array.from({ length: 12 }, (_, index) => {
    return monthsList[(index + fromMonth) % 12]
  })
}

export const monthsList = [
  {
    id: 0,
    title: 'Jan',
  },
  {
    id: 1,
    title: 'Fev',
  },
  {
    id: 2,
    title: 'Mar',
  },
  {
    id: 3,
    title: 'Avr',
  },
  {
    id: 4,
    title: 'Mai',
  },
  {
    id: 5,
    title: 'Juin',
  },
  {
    id: 6,
    title: 'Juil',
  },
  {
    id: 7,
    title: 'Aout',
  },
  {
    id: 8,
    title: 'Sept',
  },
  {
    id: 9,
    title: 'Oct',
  },
  {
    id: 10,
    title: 'Nov',
  },
  {
    id: 11,
    title: 'Déc',
  },
]
