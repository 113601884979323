import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
  FormControl,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { Controller, useForm } from 'react-hook-form'

type AddressFilter = {
  end?: Date | any
  anomalies?: boolean
}

type FilterAddressProps = {
  onSubmit: (filter: AddressFilter) => void
}

// eslint-disable-next-line @getify/proper-arrows/where
const useStyles = makeStyles(() => {
  return {
    form: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: '48px',
      width: '100%',
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
      },
    },
  }
})

export function FilterAddress(props: FilterAddressProps) {
  const { onSubmit } = props
  const methods = useForm<AddressFilter>({
    defaultValues: {
      end: 'all',
      anomalies: false,
    },
  })
  const { control, handleSubmit } = methods
  const style = useStyles()

  return (
    <form
      onChange={handleSubmit(onSubmit, (error) => console.log(error))}
      className={style.form}
    >
      <FormControl classes={{ root: style.form }}>
        <Typography variant={'h5'}>Filtres</Typography>
        <Controller
          rules={{ required: true }}
          name="end"
          control={control}
          render={(renderProps) => {
            const { field } = renderProps
            return (
              <RadioGroup
                {...field}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
              >
                <FormControlLabel
                  value="all"
                  control={<Radio color="primary" />}
                  label="Voir tout"
                />
                <FormControlLabel
                  value="nextMonth"
                  control={<Radio color="primary" />}
                  label="A faire dans les 30 jours"
                />
                <FormControlLabel
                  value="past"
                  control={<Radio color="primary" />}
                  label="en retard"
                />
              </RadioGroup>
            )
          }}
        />
        <Controller
          name="anomalies"
          control={control}
          render={(renderProps) => {
            const { field } = renderProps
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  {...field}
                  checked={field.value}
                  color="primary"
                  onChange={(event) => {
                    field.onChange(event.target.checked)
                  }}
                />
                Anomalies détectées
              </div>
            )
          }}
        />
      </FormControl>
    </form>
  )
}
