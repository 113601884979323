import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core'
import {
  Check as CheckIcon,
  QueryBuilder as QueryBuilderIcon,
} from '@material-ui/icons'
import { makeStyles, withStyles } from '@material-ui/styles'
import type { Pro } from '@willig/types/api'
import { format } from 'date-fns'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { CustomTooltip } from 'src/components/CustomtoolTip'
import { useGetList, useGetOne } from 'src/hooks/useQueries'
import { listMonths, monthsList } from 'src/libs/sortMonth'

const useStyles = makeStyles({
  container: {
    width: '90%',
    margin: '48px auto',
  },
})

// eslint-disable-next-line @getify/proper-arrows/where
const StyledTableCell = withStyles(() => {
  return {
    root: {},
    head: {
      width: 'contains',
      backgroundColor: '#E5E5E5',
      color: 'black',
      fontSize: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    body: {
      border: '1px solid #E5E5E5',
      textAlign: 'center',
      '& td:nth-of-type(0)': {
        textAlign: 'left',
      },
    },
  }
})(TableCell)

type PlanningProps = {
  proId: string
}

export function Planning(props: PlanningProps) {
  const { proId } = props
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(50)
  const sortedMonth = listMonths(0)
  const classes = useStyles()

  const { data: address } = useGetList('FullAddress', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'id', order: 'ASC' },
    filter: { pro_id: proId },
  })
  const { data: pro } = useGetOne<Pro>('Pro', { id: proId })

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const displayedData = useMemo(() => {
    const firstIndex = page * rowsPerPage
    if (address) {
      return address.data.slice(firstIndex, firstIndex + rowsPerPage)
    }
    return []
  }, [address, rowsPerPage, page])

  return (
    <div className={classes.container}>
      {pro && address && (
        <>
          <Typography
            component="h3"
            variant="h4"
            color="primary"
            align="left"
            style={{ marginBottom: '16px' }}
          >
            {`Agence ${pro.data.name.toLowerCase()}`}
          </Typography>

          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell style={{ textAlign: 'left' }}>
                    Adresse
                  </StyledTableCell>
                  {sortedMonth.map((month: any) => (
                    <StyledTableCell key={month.title}>
                      {month.title}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {displayedData.map((oneAddress: any) => (
                  <AddressRow adresse={oneAddress} key={oneAddress.id} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[50, 100, 250]}
            component="div"
            count={address.data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Éléments par page :"
          />
        </>
      )}
    </div>
  )
}

function AddressRow(props: { adresse: any }) {
  const { adresse } = props

  const next = adresse.next_intervention_date
    ? Number(format(new Date(adresse.next_intervention_date), 'MM'))
    : null
  const last = adresse.last_intervention_date
    ? Number(format(new Date(adresse.last_intervention_date), 'MM'))
    : null
  const reported = adresse.last_intervention_status === 3
  return (
    <TableRow
      style={
        reported
          ? {
              backgroundColor: '#FFAB0E1A',
              textAlign: 'left',
            }
          : { textAlign: 'left', backgroundColor: 'white' }
      }
    >
      <StyledTableCell>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            paddingRight: '10%',
          }}
        >
          <Link to={`/Adresses/${adresse.id}`}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'left',
              }}
            >
              <p style={{ margin: '0' }}>{adresse.rue}</p>
              <p style={{ margin: '0' }}>
                {`${adresse.code_postal} - ${adresse.ville}`}
              </p>
            </div>
          </Link>

          {reported && (
            <CustomTooltip
              title={
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '4px',
                    gap: '1px',
                  }}
                >
                  intervention annulée, à reprogrammer
                </div>
              }
              placement="right-end"
            >
              <QueryBuilderIcon style={{ color: 'orange' }} />
            </CustomTooltip>
          )}
        </div>
      </StyledTableCell>
      {monthsList.map((table: any) => {
        if (table.id + 1 === next || table.id + 1 === last) {
          return (
            <StyledTableCell key={table.id}>
              {<CheckIcon color="primary" />}
            </StyledTableCell>
          )
        }
        return <StyledTableCell key={table.id}></StyledTableCell>
      })}
    </TableRow>
  )
}
