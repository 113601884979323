import type { PaginationLimit, Params } from 'src/types/nxFiltertypes'

export function encodeLimitParams(limit?: PaginationLimit) {
  if (limit?.pageSize)
    return `${Math.max(0, limit.offset || 0)},${limit.pageSize}`
  return undefined
}
export function encodeOrderParams<T>(
  order?: Params<T>['order'],
): string | undefined {
  const props = Object.entries(order || {}).map(([key, value]) => {
    if (!value) return null
    if (value === 'DESC') {
      return `${key} ${value}`
    }
    return key
  })

  return props.filter(Boolean).join(',') || undefined
}
