/* eslint-disable @getify/proper-arrows/where */
type Dataprovider = {
  [key: string]: any
}

export function antiCorruptionLayerProxy(dataProvider: any) {
  const proxies = Proxies(dataProvider)
  return new Proxy(dataProvider, {
    get(_, name: any) {
      return function wrappedMethod(resource: string, params: any) {
        if (resource in proxies) {
          // @ts-ignore
          const proxy = proxies[resource]
          if (name in proxy) {
            // @ts-ignore
            return proxy[name](params)
          }
        }
        return dataProvider[name](resource, params)
      }
    },
  })
}

const Proxies = (dataProvider: Dataprovider) => {
  return {
    FullAddress: FullAddressProxy(dataProvider),
    UserExtranet: UserExtranetProxy(dataProvider),
  }
}

const FullAddressProxy = (dataProvider: Dataprovider) => {
  return {
    getList: async (params: any) => {
      const values = await dataProvider.getList('FullAddress', params)
      const result = await values.data.map((value: any) => {
        const parsedAnomalies = value.anomalies
          ? JSON.parse(value.anomalies)
          : null
        return {
          ...value,
          anomalies: parsedAnomalies,
        }
      })
      return { data: result }
    },
    getOne: async (params: any) => {
      const value = await dataProvider.getOne('FullAddress', params)
      const result = {
        ...value.data,
        anomalies: value.data.anomalies
          ? Object.values(JSON.parse(value.data.anomalies))
          : [],
      }
      return { data: result }
    },
  }
}

const UserExtranetProxy = (dataProvider: any) => {
  return {
    getOne: async (params: any) => {
      const value = await dataProvider.getOne('UserExtranet', { id: params.id })
      const pro_ids = Object.values(JSON.parse(value.data.pro_ids))
      const result = {
        ...value.data,
        pro_ids,
      }
      return { data: result }
    },
  }
}
