import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

// eslint-disable-next-line @getify/proper-arrows/where
const useStyles = makeStyles(() => {
  return {
    divider: {
      width: '100%',
      margin: '32px auto',
    },
  }
})

export function TitleDivider() {
  const classes = useStyles()
  return <Divider className={classes.divider} />
}
