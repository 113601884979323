/* eslint-disable @getify/proper-arrows/where */
import type { AxiosInstance } from 'axios'
import { encodeLimitParams, encodeOrderParams } from 'src/libs/encodedParams'

import { encodeFilterParams } from '../libs/nxSearchFiltersParams'

import { useStorage } from './Storage'

type DataProviderParams = {
  httpClient: AxiosInstance
}

export type GetListParams = {
  pagination: { page: number; perPage: number }
  sort: { field: string; order: string }
  filter: any
}

export function DataProvider(params: DataProviderParams) {
  const { httpClient } = params
  const storage = useStorage()
  return {
    getList: (
      resource: string,
      requestParams: GetListParams = defaultParams,
    ) => {
      const { page, perPage } = requestParams.pagination
      const { field, order } = requestParams.sort
      return httpClient
        .get(`/${resource}`, {
          params: {
            limit: encodeLimitParams({
              pageSize: perPage,
              offset: (page - 1) * perPage,
            }),
            filter: encodeFilterParams(requestParams?.filter),
            // @ts-ignore
            order: encodeOrderParams({ [field]: order }),
            calc_rows: true,
          },
        })
        .then((response) => {
          return {
            data: response.data,
            total: parseInt(response.headers['nx-total-rows']),
          }
        })
    },
    getOne: (resource: string, requestParams: { id: string | number }) => {
      return httpClient
        .get(`${resource}/${requestParams.id}`)
        .then((response) => {
          return {
            data: response.data,
          }
        })
    },
    patch: (resource: string, payload: {}) => {
      return httpClient.patch(`${resource}`, payload).then((response) => {
        return {
          data: response.data,
        }
      })
    },
    put: (resource: string, payload: {}) => {
      return httpClient.put(`${resource}`, payload).then((response) => {
        return {
          data: response.data,
        }
      })
    },
    login: (payload: { login: string; password: string }) => {
      return httpClient
        .patch('/UserExtranet/login', payload)
        .then((response) => {
          storage.setItem('jwt', response.data.value)
          return { data: response.data }
        })
    },
  }
}

const defaultParams = {
  pagination: { page: 1, perPage: 100 },
  sort: { field: 'id', order: 'ASC' },
  filter: {},
}
