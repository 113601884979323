import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import type { FullAddress } from '@willig/types/api'
import { useParams } from 'react-router-dom'
import { useGetOne, useGetList } from 'src/hooks/useQueries'

import { AnomalieShow } from './Components/AnomalieShow'
import { TableHistoric } from './Components/TableHistoric'

const useStyles = makeStyles({
  container: {
    width: '90%',
    margin: '48px auto',
  },
})

export function OneAddress() {
  const style = useStyles()
  const { id } = useParams()
  const { data: address, isLoading } = useGetOne<FullAddress>('FullAddress', {
    id: id,
  })
  const { data: interventions } = useGetList('Intervention', {
    pagination: { page: 1, perPage: 100 },
    sort: { field: 'rdv_date', order: 'DESC' },
    filter: { adresse_id: id },
  })

  return (
    <div>
      {!isLoading && address && (
        <>
          <Typography
            align="left"
            variant="h4"
            color="primary"
          >{`${address?.data.rue} - ${address?.data.code_postal} ${address?.data.ville}`}</Typography>
          <div className={style.container}>
            <Typography
              align="left"
              variant="h5"
              style={{ marginBottom: '16px' }}
            >
              Anomalies relevées
            </Typography>
            {address?.data.has_anomalies ? (
              <AnomalieShow
                key={address.data.id}
                anomalies={address.data.anomalies}
              />
            ) : (
              <p style={{ textAlign: 'left' }}>Aucune anomalies</p>
            )}

            <Typography
              align="left"
              variant="h5"
              style={{ marginBottom: '16px' }}
            >
              Historique
            </Typography>
            {interventions && interventions.data.length > 0 ? (
              <TableHistoric
                data={interventions.data.filter(
                  (intervention: any) => intervention.status === 2,
                )}
              />
            ) : (
              <p style={{ textAlign: 'left' }}>Pas d'historique </p>
            )}
          </div>
        </>
      )}
    </div>
  )
}
