import { Typography } from '@material-ui/core'
import jwt_decode from 'jwt-decode'
import { useGetOne } from 'src/hooks/useQueries'

import { Planning } from 'src/pages/Planning/Components/Planning'
import type { ExtendedUserExtranet } from 'src/types/extendedTypes'

import { TitleDivider } from '../../components/Divider'

export function PlanningList() {
  const token = localStorage.getItem('jwt') ?? ''
  const me = jwt_decode<any>(token)
  const { data: user, isLoading } = useGetOne<ExtendedUserExtranet>(
    'UserExtranet',
    {
      id: me.id,
    },
  )
  return (
    <>
      <Typography component="h3" variant="h3" color="primary" align="left">
        Planning prévisionnel
      </Typography>
      <TitleDivider />
      {!isLoading &&
        user?.data.pro_ids &&
        user?.data.pro_ids.map((pro: string) => {
          return <Planning key={pro} proId={pro} />
        })}
    </>
  )
}
