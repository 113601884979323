import jwtDecode from 'jwt-decode'
import { useState, useMemo, useLayoutEffect } from 'react'
import type { PropsWithChildren } from 'react'
import { useEnv } from 'src/adapters/Env'
import { HTTPClient, HttpClientContext } from 'src/adapters/nxHttpClient'
import { useStorage } from 'src/adapters/Storage'
import { SignIn } from 'src/pages/Login/Login'
import { Recovery } from 'src/pages/Recovery/Recovery'

export function AuthProvider(props: PropsWithChildren<{}>) {
  const { children } = props
  const env = useEnv()
  const location = window.location.pathname
  const [apiToken, setToken] = useStoredToken()
  const httpClient = useMemo(
    () =>
      HTTPClient({
        env,
        getAccessToken: () => apiToken,
        onInvalidCredentials() {
          alert("Votre jeton d'authentification n'est pas valide")
          setToken('')
        },
      }),
    [env, apiToken, setToken],
  )

  if (!apiToken) {
    if (location.includes('recover_password')) {
      return (
        <HttpClientContext.Provider value={httpClient}>
          <Recovery title="Récupération" />
        </HttpClientContext.Provider>
      )
    }
    if (location.includes('create_password')) {
      return (
        <HttpClientContext.Provider value={httpClient}>
          <Recovery title="Création" />
        </HttpClientContext.Provider>
      )
    }
    return (
      <HttpClientContext.Provider value={httpClient}>
        <SignIn />
      </HttpClientContext.Provider>
    )
  }
  return (
    <HttpClientContext.Provider value={httpClient}>
      {children}
    </HttpClientContext.Provider>
  )
}

function useStoredToken() {
  const storage = useStorage()

  const [apiToken, setToken] = useState('')

  useLayoutEffect(() => {
    const token = storage.getItem('jwt')
    if (token && isValidToken(token)) {
      setToken(token)
      storage.setItem('jwt', token)
    }
    if (token && !isValidToken(token)) {
      storage.removeItem('jwt')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return [apiToken, setToken] as const
}

function isValidToken(token?: string): token is string {
  if (!token) return false
  const { exp } = jwtDecode<any>(token)
  if (exp * 1000 < Date.now()) return false
  return true
}
