import axios from 'axios'
import type { AxiosInstance } from 'axios'
import { createContext, useContext } from 'react'

import { config } from '../config'

type HTTPClientProps = {
  getAccessToken: () => string | undefined
  env: { API_URL: string }
  onInvalidCredentials: () => void
}
export function HTTPClient(props: HTTPClientProps) {
  const { getAccessToken, env } = props
  const httpClient = axios.create({
    baseURL: env.API_URL,
  })

  httpClient.interceptors.request.use(async (requestConfig) => {
    const token = getAccessToken()
    if (!token) {
      return {
        ...requestConfig,
        headers: {
          ...requestConfig.headers,
          'nx-app-identifier': config.APP_NAME,
          'nx-app-install': navigator.userAgent,
          'nx-app-version': config.APP_VERSION,
        },
      }
    }

    return {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        jwt: token,
        'nx-app-identifier': 'extranet',
        'nx-app-install': 'firefox',
        'nx-app-version': '1.1.0',
      },
    }
  })

  return httpClient
}

export const HttpClientContext = createContext<AxiosInstance | undefined>(
  undefined,
)

export function useHttpClient(): AxiosInstance {
  const httpClient = useContext(HttpClientContext)

  if (!httpClient) throw new Error('Please provide an HttpClient')

  return httpClient
}
