import {
  Button,
  TextField,
  Typography,
  Container,
  Divider,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { useMutation } from 'react-query'
import { antiCorruptionLayerProxy } from 'src/adapters/antiCorruptionLayerProxy'
import { DataProvider } from 'src/adapters/DataProvider'
import { useHttpClient } from 'src/adapters/nxHttpClient'
import logo from 'src/UI/theme/logo.png'

// eslint-disable-next-line @getify/proper-arrows/where
const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '800px',
    },
    divider: {
      width: '80px',
      height: '3px',
      backgroundColor: theme.palette.primary.light,
      margin: '16px auto',
    },
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: '50%',
      padding: theme.spacing(3),
      backgroundColor: theme.palette.background.default,
    },
    form: {
      width: '100%',
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
      height: theme.spacing(6),
      borderRadius: theme.spacing(3),
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.common.white,
    },
    field: {
      backgroundColor: theme.palette.background.paper,
    },
  }
})

export function SignIn() {
  const classes = useStyles()
  const httpClient = useHttpClient()
  const client = antiCorruptionLayerProxy(DataProvider({ httpClient }))
  const { mutate } = useMutation((payload: {}) => client.login(payload))

  function handleSubmit(event: any) {
    event.preventDefault()
    const payload = {
      login: event.target[0].value,
      password: event.target[2].value,
    }
    mutate(payload, {
      onSuccess: function () {
        window.location.href = '/'
      },
      onError: function (error) {
        console.log(error)
      },
    })
  }

  return (
    <Container component="main" className={classes.container}>
      <img src={logo} alt="logo" style={{ marginBottom: '40px' }} />
      <Typography component="h1" variant="h4" style={{ fontWeight: 'bold' }}>
        Bienvenue dans votre
        <span style={{ color: '#7CB053' }}> espace client</span>
      </Typography>
      <Divider classes={{ root: classes.divider }} />
      <Typography component="h4" variant="h6" style={{ textAlign: 'center' }}>
        Connectez-vous à l'aide des identifiants transmis par nos équipes. Pas
        encore d'identifiants ?
        <a
          style={{ fontWeight: 'bold' }}
          href="mailto:contact@ramonage-willig.fr"
        >
          Contactez-nous pour les obtenir
        </a>
      </Typography>

      <div className={classes.paper}>
        <Typography
          component="h1"
          variant="h5"
          style={{ fontWeight: 'bold', width: '100%', textAlign: 'left' }}
        >
          Connexion
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Identifiant"
            name="email"
            autoComplete="email"
            autoFocus
            className={classes.field}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Mot de passe"
            type="password"
            id="password"
            autoComplete="current-password"
            className={classes.field}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={classes.submit}
          >
            Connexion
          </Button>
        </form>
      </div>
    </Container>
  )
}
