import type { PropsWithChildren } from 'react'

export function Layout(props: PropsWithChildren<{}>) {
  const { children } = props
  return (
    <div style={{ padding: '48px', display: 'flex', flexDirection: 'column' }}>
      {children}
    </div>
  )
}
