import { Typography } from '@material-ui/core'
import jwt_decode from 'jwt-decode'
import { useState } from 'react'

import { useGetOne } from 'src/hooks/useQueries'
import { AddressList } from 'src/pages/Address/Components/AddressList'
import type { ExtendedUserExtranet } from 'src/types/extendedTypes'

import { TitleDivider } from '../../components/Divider'

import { FilterAddress } from './Components/FilterAddress'

type AddressFilter = {
  end?: Date | any
  anomalies?: boolean
}

export function Address() {
  const [filters, setFilters] = useState<AddressFilter>({})
  const token = localStorage.getItem('jwt') ?? ''
  const me = jwt_decode<any>(token)

  const { data: user, isLoading } = useGetOne<ExtendedUserExtranet>(
    'UserExtranet',
    { id: me.id },
  )

  function onSubmit(values: AddressFilter) {
    setFilters(values)
  }

  return (
    <>
      <Typography component="h4" variant="h3" color="primary" align="left">
        Vos adresses
      </Typography>
      <TitleDivider />
      {!isLoading && user?.data.pro_ids && (
        <>
          <FilterAddress onSubmit={onSubmit} />
          {user.data.pro_ids.map((pro) => {
            return <AddressList key={pro} proId={pro} filters={filters} />
          })}
        </>
      )}
    </>
  )
}
