import env from '@beam-australia/react-env'
import { formatUrl, isMissing, validateAppEnv } from '@willig/utils'
import { createContext, useContext } from 'react'

export type IEnv = ReturnType<typeof Env>

export function Env() {
  function resolve(
    name: string,
    getDefaultValue: () => string = () => isMissing(name),
  ): string {
    return env(name) ?? getDefaultValue()
  }

  const APP_ENV = validateAppEnv(resolve('APP_ENV', () => 'production'))

  return {
    APP_ENV: APP_ENV,
    IS_DEV: APP_ENV === 'development',
    BASE_URL: formatUrl(resolve('BASE_URL')),
    API_URL: formatUrl(resolve('API_URL')),
    SENTRY_DSN: resolve('SENTRY_DSN'),
  } as const
}

export const EnvContext = createContext<IEnv | undefined>(undefined)

export function useEnv(): IEnv {
  const resolved = useContext(EnvContext)

  if (!resolved) {
    throw new Error('Please provide an env object')
  }

  return resolved
}
