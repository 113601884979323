import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import theme from 'src/UI/theme'

import { Env, EnvContext } from './adapters/Env'
import { StorageContext, Storage } from './adapters/Storage'
import App from './App'
import { AuthProvider } from './AuthProvider'
import reportWebVitals from './reportWebVitals'

const env = Env()
const storage = Storage()
const queryClient = new QueryClient()

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <EnvContext.Provider value={env}>
        <StorageContext.Provider value={storage}>
          <ThemeProvider theme={theme}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </ThemeProvider>
        </StorageContext.Provider>
      </EnvContext.Provider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
