import React from 'react'

import './App.css'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { config } from 'src/config'

import { Address } from './pages/Address/List'
import { OneAddress } from './pages/Address/Show'
import { SignIn } from './pages/Login/Login'
import { PlanningList } from './pages/Planning/List'
import { Recovery } from './pages/Recovery/Recovery'
import { Header } from './UI/Header'

import { Layout } from './UI/Layout'

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Header />
          <Layout>
            <Routes>
              <Route path="/" element={<Address />} />
              <Route path="/Login" element={<SignIn />} />
              <Route path="/Adresses" element={<Address />} />
              <Route path="/Adresses/:id" element={<OneAddress />} />
              <Route path="/Planning" element={<PlanningList />} />
              <Route
                path="/recover_password"
                element={<Recovery title="Récupération" />}
              />
              <Route
                path="/create_password"
                element={<Recovery title="Création" />}
              />
            </Routes>
            <LayoutVersion />
          </Layout>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  )
}

export function LayoutVersion() {
  return <>{config.APP_RELEASE}</>
}

export default App
